import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, firestore } from "./firebase";

export function useUserData() {
  const [user] = useAuthState(auth);
  const [username, setUsername] = useState(null);
  const [connectId, setConnectId] = useState(null);

  useEffect(() => {
    let unsubscribe;
    if (user) {
      const ref = firestore.collection("users").doc(user.uid);
      //console.log("REF", ref);
      unsubscribe = ref.onSnapshot((doc) => {
        //console.log("DOC DATA", doc.data());
        setUsername(doc.data()?.email);
        setConnectId(doc.data()?.stripeConnectId);
      });
    } else {
      setUsername(null);
      setConnectId(null);
    }

    return unsubscribe;
  }, [user]);

  //console.log(username, connectId);
  return { user, username, connectId };
}
