import React, { useState, useContext, useEffect } from "react";
import firebase from "firebase/compat/app";
import { auth, firestore } from "./lib/firebase";
import { UserContext } from "./lib/context";
import { fetchFromAPI } from "./helpers";
import { QRCodeSVG } from "qrcode.react";
import { Link } from "react-router-dom";

export default function Customers() {
  const { user, connectId } = useContext(UserContext);

  return (
    <>
      {/* <h3>Customers</h3> */}
      {user ? (
        <>
          <AccountSetup user={user} connectId={connectId} />
          {/* <SaveCard user={user} /> */}
        </>
      ) : (
        <h2>Sign in with Google to start receiving tips with Tippee.</h2>
      )}
    </>
  );
}

const slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

function AccountSetup({ connectId, user }) {
  const [accountStatus, setAccountStatus] = useState();
  const [accountLink, setAccountLink] = useState();

  useEffect(() => {
    if (connectId) {
      //console.log("User, handle setup", connectId);
      handleSetup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectId]);

  const handleSetup = async () => {
    const { payouts_enabled } = await fetchFromAPI(connectId, {
      method: "GET",
    });
    //console.log(stripeConnectAccount.payouts_enabled);
    setAccountStatus(payouts_enabled);
  };

  const handleCompleteSignUp = async (e) => {
    e.preventDefault();
    const newAccountLink = await fetchFromAPI(connectId, {
      method: "POST",
    });

    setAccountLink(newAccountLink);
  };

  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      {/* <button onClick={handleSetup}>Account Setup</button> */}
      {/* <button onClick={handleAccountStatus}>Check Account Status</button> */}

      {user && (
        <>
          <img
            src={user.photoURL}
            alt={`${user.displayName}`}
            style={{ borderRadius: 50, margin: "1rem auto 1rem auto" }}
            width={100}
          />
          <p style={{ fontWeight: "bold" }}>{user.displayName}</p>
        </>
      )}
      <p
        className={accountStatus ? "badge badge-success" : "badge badge-danger"}
        style={{ width: "70%", margin: "0rem auto 1rem auto" }}
      >
        {accountStatus ? "Active" : "Inactive"}
      </p>

      {/* COMPLETE SIGN UP */}
      {!accountStatus && (
        <button className="btn btn-primary" onClick={handleCompleteSignUp}>
          Generate Sign Up Link
        </button>
      )}
      {accountLink && (
        <p>
          Signup Link: {<a href={`${accountLink.url}`}>Account Sign Up</a>} Link
          expires at {Date(accountLink.expires_at)}
        </p>
      )}

      {/* If payout enabled, account status = true, generate QR */}
      {accountStatus && (
        <div
          style={{
            display: "grid",
            gridTemplateRows: "128px 2rem",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <QRCodeSVG
            style={{ margin: "auto" }}
            value={`${process.env.REACT_APP_WEB_URL}/${connectId}`}
          />
          <Link to={`../${connectId}`} style={{ fontSize: "0.875rem" }}>
            /{slugify(user.displayName)}
          </Link>
        </div>
      )}
    </div>
  );
}

export function SignIn() {
  const signIn = async () => {
    const credential = await auth.signInWithPopup(
      new firebase.auth.GoogleAuthProvider()
    );
    const { uid, email } = credential.user;
    firestore.collection("users").doc(uid).set({ email }, { merge: true });
  };

  return (
    <button className="" onClick={signIn}>
      Sign In
    </button>
  );
}

export function SignOut({ user }) {
  useEffect(() => {}, [user]);
  return (
    user && (
      <button className="" onClick={() => auth.signOut()}>
        Sign Out
      </button>
    )
  );
}

// function SaveCard(props) {
//   const stripe = useStripe();
//   const elements = useElements();
//   const user = props.user;

//   const [setupIntent, setSetupIntent] = useState();
//   const [wallet, setWallet] = useState([]);

//   // Get the users wallet on mount
//   useEffect(() => {
//     getWallet();
//     // eslint-disable-next-line
//   }, [user]);

//   const getWallet = async () => {
//     if (user) {
//       const paymentMethods = await fetchFromAPI("wallet", { method: "GET" });
//       setWallet(paymentMethods);
//     }
//   };

//   // Create the Setup Intent
//   const createSetupIntent = async (event) => {
//     const si = await fetchFromAPI("wallet", { method: "POST" });
//     setSetupIntent(si);
//   };

//   // Handle the submission of card details
//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const cardElement = elements.getElement(CardElement);
//     // Confirm Card Setup
//     const { setupIntent: updatedSetupIntent, error } =
//       await stripe.confirmCardSetup(setupIntent.client_secret, {
//         payment_method: { card: cardElement },
//       });

//     if (error) {
//       alert(error.message);
//       console.error(error);
//     } else {
//       setSetupIntent(updatedSetupIntent);
//       await getWallet();
//       alert("Success! Card added to your wallet");
//     }
//   };

//   return (
//     <>
//       <button onClick={createSetupIntent} hidden={setupIntent}>
//         Attach new Credit Card
//       </button>
//       {setupIntent && (
//         <form onSubmit={handleSubmit}>
//           <CardElement />
//           <button type="submit">Save Credit Card</button>
//         </form>
//       )}
//       <div>
//         <h3>Retrieve all Payment Sources</h3>
//         <select className="mb-2 form-select">
//           {wallet.map((paymentSource) => (
//             <CreditCard key={paymentSource.id} card={paymentSource.card} />
//           ))}
//         </select>
//       </div>
//       <div>
//         <SignOut user={user} />
//       </div>
//     </>
//   );
// }

// function CreditCard(props) {
//   const { last4, brand, exp_month, exp_year } = props.card;
//   return (
//     <option>
//       {brand} **** **** **** {last4} expires {exp_month}/{exp_year}
//     </option>
//   );
// }
