import "./App.css";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { Fail, Processed } from "./Tippee";
import Payments from "./Payments";
import Customers, { SignIn, SignOut } from "./Customers";
import Tippee from "./Tippee";
import { UserContext } from "./lib/context";
import { useUserData } from "./lib/hooks";
import logo from "./images/tippee-logo.png";
import { auth } from "./lib/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { loadStripe } from "@stripe/stripe-js";

// Stripe public key
export const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY
);

function App() {
  const userData = useUserData();
  const [user] = useAuthState(auth);

  // useEffect(() => {
  //   fetchFromAPI("payments", {
  //     method: "POST",
  //     body: { amount: 1000 },
  //   }).then((data) => {
  //     console.log("USE EFFECT FETCH", data, typeof data);
  //     setClientSecret(data.client_secret);
  //   });
  // }, []);

  return (
    <>
      <UserContext.Provider value={userData}>
        <Router>
          <div className="app-container" style={{ padding: "0 5vw 0 5vw" }}>
            <nav className="navbar" style={{ fontSize: "0.875rem" }}>
              <ul
                className="d-flex justify-content-between nav me-auto"
                style={{ width: "100vw", paddingTop: "2vw" }}
              >
                <li className="nav-item">
                  <Link to="/profile" className="nav-item">
                    <img src={logo} width={35} alt="home" />
                  </Link>
                </li>
                {/* <li className="nav-item">
                <Link to="/checkout" className="nav-link">
                  <span aria-label="emoji" role="img">
                    🛒
                  </span>{" "}
                  Checkout
                </Link>
              </li> */}
                {/* <li className="nav-item">
                <Link to="/payments" className="nav-link">
                  Payments
                </Link>
              </li> */}
                {/* <li className="nav-item">
                <Link to="/customers" className="nav-link">
                  Profile
                </Link>
              </li> */}
                {/* <li className="nav-item">
                <Link to="/subscriptions" className="nav-link">
                  <span aria-label="emoji" role="img">
                    🔄
                  </span>{" "}
                  Subscriptions
                </Link>
              </li> */}
                {/* <li className="nav-item">
                <Link to="/tippee" className="nav-link">
                  <span aria-label="emoji" role="img">
                    🙏
                  </span>{" "}
                  Tippee
                </Link>
              </li> */}
                <li className="nav-item ">
                  {user ? <SignOut user={user} /> : <SignIn />}
                </li>
              </ul>
            </nav>

            <main>
              <Routes>
                {/* <Route path="/checkout" element={<Checkout />} /> */}
                <Route path="/payments" element={<Payments />} />
                <Route path="/profile" element={<Customers />} />
                {/* <Route path="/subscriptions" element={<Subscriptions />} /> */}
                <Route path="/:tippee" element={<Tippee />} />
                <Route path="/processed" element={<Processed />} />
                <Route path="/failed" element={<Fail />} />
                <Route path="/" element={<Home />} />
              </Routes>
            </main>
          </div>
        </Router>
      </UserContext.Provider>
    </>
  );
}

function Home() {
  return (
    <>
      <h2>Welcome to Tippee! New Zealand's first cashless tipping app.</h2>
    </>
  );
}

export default App;
