import { auth } from "./lib/firebase";
const API = process.env.REACT_APP_GCLOUD_SERVER; // "http://localhost:3000";

/**
 * A helper function to fetch data from the API
 */
export async function fetchFromAPI(endpointURL, options = { method: "GET" }) {
  //console.log("FETCH FROM API", options, endpointURL);
  const { method, body } = { ...options };

  const user = auth.currentUser;
  const token = user && (await user.getIdToken());
  //console.log(body, token);
  const res = await fetch(`${API}/${endpointURL}`, {
    method,
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // context for current user
    },
  });

  return res.json();
}
