import React, { useState } from "react";
import { fetchFromAPI } from "./helpers";
import { useParams } from "react-router-dom";
import ConfirmPayment from "./ConfirmPayment";
import { stripePromise } from "./App";
import { Elements } from "@stripe/react-stripe-js";

export default function Payments() {
  const { tippee } = useParams();

  const [amount, setAmount] = useState(1000);
  //const [coverFees, setCoverFees] = useState(0);
  const [customAmount, setCustomAmount] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState();
  const [topup, setTopup] = useState(0);
  const [topupToggle, setTopupToggle] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const stripePercent = 0.027; // percent
  const stripeMin = 30; // cents
  const platformPercent = 0; // percent
  const fees = parseInt(
    Math.round((amount + topup) * (stripePercent + platformPercent) + stripeMin)
  );
  const appearance = {
    theme: "stripe",
  };
  // Create a payment intent on the server
  const createPaymentIntent = async (e) => {
    e.preventDefault();
    // Clamp amount to stripe min/max
    const validAmount = Math.min(Math.max(amount, 100), 9999999);
    setAmount(validAmount);
    const customer = await fetchFromAPI("create-guest", {
      method: "POST",
      body: { name, email },
    });
    // Make the API Request
    const pi = await fetchFromAPI("payments-detailed", {
      method: "POST",
      body: {
        amount: amount + topup,
        currency: "nzd",
        transfer_data: {
          amount: amount + topup - fees,
          destination: tippee, // check valid account...
        },
        customer: customer.id,
        receipt_email: customer.receipt_email,
      },
    });

    setPaymentIntent(pi);
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const cardElement = elements.getElement(PaymentElement);

  //   // Confirm Card Payment
  //   const { paymentIntent: updatedPaymentIntent, error } =
  //     await stripe.confirmCardPayment(paymentIntent.client_secret, {
  //       payment_method: { card: cardElement },
  //     });

  //   if (error) {
  //     console.error(error);
  //     error.payment_intent && setPaymentIntent(error.payment_intent);
  //   } else {
  //     // console.log(updatedPaymentIntent);
  //     setPaymentIntent(updatedPaymentIntent);
  //   }
  // };

  const handleTopup = () => {
    if (!topupToggle) {
      // topup

      const newTopup = parseInt(
        Math.round(
          (amount + stripeMin) / (1 - stripePercent - platformPercent) - amount
        )
      );
      //console.log(newTopup);
      setTopup(newTopup);
    } else {
      // remove topup
      setTopup(0);
    }
    setTopupToggle(!topupToggle);
  };

  // const handleCoverFees = (e) => {
  //   let newCoverFees = 0;
  //   coverFees == 1 ? (newCoverFees = 0) : (newCoverFees = 1);
  //   setCoverFees(newCoverFees);
  // };

  return (
    <>
      <div id="top" hidden></div>
      {!paymentIntent && (
        <>
          <div className=" text-center">
            <p>How much would you like to tip?</p>
            <div className="d-flex justify-content-between">
              {[5, 10, 20].map((value) => (
                <button
                  className="btn btn-success p-2"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                  onClick={(e) => {
                    setCustomAmount(false);
                    setAmount(value * 100);
                  }}
                >
                  {value}
                </button>
              ))}
              <button
                className=" btn btn-success p-2"
                style={{ width: "2.5rem", height: "2.5rem" }}
                onClick={(e) => setCustomAmount(!customAmount)}
              >
                _
              </button>
            </div>
            <hr />
            <div className="mt-2 text-start" hidden={!customAmount}>
              <fieldset>
                <label>Custom amount</label>
                <input
                  className="p-2 form-control"
                  style={{ fontSize: "0.875rem" }}
                  type="number"
                  value={amount / 100}
                  min={0}
                  disabled={paymentIntent}
                  onChange={(e) => {
                    if (e.target.value >= 0)
                      setAmount(parseInt(e.target.value * 100));
                  }}
                />
              </fieldset>

              <hr />
            </div>
          </div>
          <div className="" hidden={amount < 1}>
            <div className="row">
              <div className="col">Amount</div>
              <div className="col" style={{ textAlign: "right" }}>
                {((amount + topup) / 100).toFixed(2)}
              </div>
            </div>
            <div className="row">
              <div className="col">Fees</div>
              <div className="col" style={{ textAlign: "right" }}>
                - {(fees / 100).toFixed(2)}
              </div>
            </div>
            <div className="row" style={{ fontWeight: "bold" }}>
              <div className="col-8">They receive</div>
              <div className="col-4" style={{ textAlign: "right" }}>
                {((amount + topup - fees) / 100).toFixed(2)}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">
                <p className="mb-0">Top up to cover fees?</p>
              </div>
              <div className="col">
                <div className="form-check form-switch d-flex justify-content-end">
                  <label className="form-check-label" hidden>
                    Tip top up switch
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    onClick={handleTopup}
                    disabled={paymentIntent}
                  />
                </div>
              </div>
            </div>
            <hr />

            <p style={{ fontWeight: "bold" }}>
              A receipt will be sent to your email
            </p>
            <form onSubmit={createPaymentIntent}>
              <fieldset>
                <label>Name</label>
                <input
                  className="p-1 mb-2 form-control"
                  style={{ fontSize: "0.875rem" }}
                  type="text"
                  value={name}
                  for="name"
                  autoComplete="name"
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </fieldset>
              <fieldset>
                <label>Email</label>
                <input
                  className="p-1 mb-2 form-control"
                  style={{ fontSize: "0.875rem" }}
                  type="text"
                  value={email}
                  for="email"
                  autocomplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </fieldset>
              <hr />
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-success"
                  style={{ width: "100%" }}
                  disabled={amount <= 0}
                  hidden={paymentIntent}
                >
                  Confirm{" "}
                  {((parseInt(amount) + parseInt(topup)) / 100).toFixed(2)}
                </button>
              </div>
            </form>
          </div>
        </>
      )}
      <div className="" hidden={amount >= 1}>
        <p className="badge badge-danger">Amount must be greater than $1</p>
      </div>
      {paymentIntent && (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret: paymentIntent.client_secret, appearance }}
        >
          {" "}
          <ConfirmPayment
            paymentIntent={paymentIntent}
            tip={amount}
            fees={fees}
          />
        </Elements>
      )}
      {paymentIntent && (
        <div className="mb-2">
          <p className="badge badge-waring">{paymentIntent.status}</p>
        </div>
      )}
      <div>
        <div
          className="row text-muted small mt-2 text-center"
          style={{ fontSize: "0.75rem" }}
        >
          <p>
            <a href="#top">Terms and Conditions</a> and{" "}
            <a href="#top">Privacy Policy</a>.
          </p>
        </div>
      </div>
    </>
  );
}

export const Processed = () => {
  const url = window.location.href;
  const status = new URL(url).searchParams.get("redirect_status");

  return (
    <>
      <h2>Processed: {status}</h2>
    </>
  );
};

export const Fail = () => {
  return <h2>Failed.</h2>;
};

// $0.00 , value int
export const displayCurrency = (value) => {
  return (value / 100).toFixed(2);
};
