import React, { useState } from "react";
import { fetchFromAPI } from "./helpers";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

export default function Payments() {
  const stripe = useStripe();
  const elements = useElements();

  const [amount, setAmount] = useState(0);
  const [paymentIntent, setPaymentIntent] = useState();

  // Create a payment intent on the server
  const createPaymentIntent = async (event) => {
    // Clamp amount to stripe min/max
    const validAmount = Math.min(Math.max(amount, 50), 9999999);
    setAmount(validAmount);

    // Make the API Request
    const pi = await fetchFromAPI("payments", {
      method: "POST",
      body: { amount: validAmount },
    });
    setPaymentIntent(pi);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const cardElement = elements.getElement(CardElement);

    // Confirm Card Payment
    const { paymentIntent: updatedPaymentIntent, error } =
      await stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: { card: cardElement },
      });

    if (error) {
      console.error(error);
      error.payment_intent && setPaymentIntent(error.payment_intent);
    } else {
      // console.log(updatedPaymentIntent);
      setPaymentIntent(updatedPaymentIntent);
    }
  };

  return (
    <>
      <h3 className="mb-2">Payments</h3>
      {paymentIntent && (
        <div className="mb-2">
          <p>Payment Intent Status: {paymentIntent.status}</p>
        </div>
      )}
      <div className="mb-2">
        <label>Stripe amount</label>
        <input
          className="p-1 mb-2 form-control"
          style={{ fontSize: "0.875rem" }}
          type="number"
          value={amount}
          disabled={paymentIntent}
          onChange={(e) => setAmount(e.target.value)}
        />
        <button
          className="btn btn-primary"
          disabled={amount <= 0}
          onClick={createPaymentIntent}
          hidden={paymentIntent}
        >
          Ready to Pay ${(amount / 100).toFixed(2)}
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <label>Card details</label>
        <CardElement />
        <button className="btn btn-success mb-2" type="submit">
          Pay
        </button>
      </form>
    </>
  );
}
